import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {Header, Footer} from './';
import { SiteContext } from '../context';

const SignupComplete = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const [msg, setMsg] = useState('');

    const {apiUrl, categories} = useContext(SiteContext);
    let navigate = useNavigate();
    let location = useLocation().state;
    let {completemsg} = location;


    return (
      <div>
        <Header />
        <div className='container'>
          <div className='row my-3 mx-auto p-2'>
            <div className='col-4 text-center text-light bg-success'>
              Step 1 of 3
            </div>  
            <div className='col-4 text-center text-light bg-success'>
              Step 2 of 3
            </div>  
            <div className='col-4 text-center text-light bg-success'>
              Step 3 of 3
            </div>  
          </div>
       
        <div className='container'>

        <h3 className='my-2'>Sign up Completed</h3>

        {msg !== '' ? <div className='my-4'><span className='p-2 my-2 text-dark'>{msg}</span></div> : null}
        {errorMsg !== '' ? <div className='my-4'><span className='p-2 my-2 border border-danger text-danger'>{errorMsg}</span></div> : null}

        <p>{completemsg}</p>

        <Link to='/'>Home</Link>
         
      </div>
      </div>
      <Footer />
    </div>
    )
}

export default SignupComplete;