import React, { useState, useContext } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { SiteContext } from '../context';

const validationSchema = Yup.object().shape({
    username: Yup.string()
      .label('Username')
      .required()
      .min(4, 'Must have at least 4 characters'),
    firstname: Yup.string()
      .label('First name')
      .required()
      .min(3, 'Must have at least 3 characters'),
    lastname: Yup.string()
      .label('Last name')
      .required()
      .min(3, 'Must have at least 3 characters'),
    phone: Yup.string()
      .label('Mobile phone')
      .required()
      .min(11, 'Must have at least 11 characters')
      .max(14, 'Must have a maximum of 14 characters'),
    email: Yup.string()
      .label('Email')
      .email('Enter a valid email')
      .required('Please enter a registered email'),
    address: Yup.string()
      .label('Address')
      .required()
      .min(5, 'Address must have at least 5 characters '),
    locstate: Yup.string()
      .label('State')
      .required(),
    country: Yup.string()
      .label('Country')
      .required()
  });

  const africancountries = [
    {"id": 1, "name": "Algeria"},
    {"id": 2, "name": "Angola"},
    {"id": 3, "name": "Benin"},
    {"id": 4, "name": "Botswana"},
    {"id": 5, "name": "Burkina Faso"},
    {"id": 6, "name": "Burundi"},
    {"id": 7, "name": "Cameroun"},
    {"id": 8, "name": "Canary Islands"},
    {"id": 9, "name": "Cape Verde"},
    {"id": 10, "name": "Central African Republic"},
    {"id": 11, "name": "Chad"},
    {"id": 12, "name": "Comoros"},
    {"id": 13, "name": "Côte d'Ivoire"},
    {"id": 14, "name": "Democratic Republic of the Congo"},
    {"id": 15, "name": "Djibouti"},
    {"id": 16, "name": "Egypt"},
    {"id": 17, "name": "Equatorial Guinea"},
    {"id": 18, "name": "Eritrea"},
    {"id": 19, "name": "Ethiopia"},
    {"id": 20, "name": "Gabon"},
    {"id": 21, "name": "Gambia"},
    {"id": 22, "name": "Ghana"},
    {"id": 23, "name": "Guinea"},
    {"id": 24, "name": "Guinea-Bissau"},
    {"id": 25, "name": "Kenya"},
    {"id": 26, "name": "Lesotho"},
    {"id": 27, "name": "Liberia"},
    {"id": 28, "name": "Libya"},
    {"id": 29, "name": "Madagascar"},
    {"id": 30, "name": "Madeira"},
    {"id": 31, "name": "Malawi"},
    {"id": 32, "name": "Mauritania"},
    {"id": 33, "name": "Mauritius"},
    {"id": 34, "name": "Morocco"},
    {"id": 35, "name": "Mozambique"},
    {"id": 36, "name": "Namibia"},
    {"id": 37, "name": "Niger"},
    {"id": 38, "name": "Nigeria"},
    {"id": 39, "name": "Republic of the Congo"},
    {"id": 40, "name": "Rwanda"},
    {"id": 41, "name": "Saint Helena"},
    {"id": 42, "name": "São Tomé and Príncipe"},
    {"id": 43, "name": "Senegal"},
    {"id": 44, "name": "Seychelles"},
    {"id": 45, "name": "Sierra Leone"},
    {"id": 46, "name": "Somalia"},
    {"id": 47, "name": "South Africa"},
    {"id": 48, "name": "Sudan"},
    {"id": 49, "name": "Swaziland"},
    {"id": 50, "name": "Tanzania"},
    {"id": 51, "name": "Togo"},
    {"id": 52, "name": "Tunisia"},
    {"id": 53, "name": "Uganda"},
    {"id": 54, "name": "Western Sahara"},
    {"id": 55, "name": "Zambia"},
    {"id": 56, "name": "Zimbabwe"}
  ];


  const arrayrange = (start, end) => {
    let myArray = [];
    for (let i = start; i <= end; i++) {
      myArray.push(i);
    }
    return myArray;
};
  
const UpdateSignupForm = ({handleSubmit, updateItem, setShowModal}) => {

  const {apiUrl} = useContext(SiteContext);

  const [errorMsg, setErrorMsg] = useState('');

    return (
      <div>
        <div className='container'>

          {errorMsg !== '' ? <span className='p-2 my-2 border border-danger text-danger'>{errorMsg}</span> : null}

          <Formik
                    initialValues={{
                      id: updateItem.id ? updateItem.id : '',
                      username: updateItem.username ? updateItem.username : '',
                      firstname: updateItem.firstname ? updateItem.firstname : '',
                      lastname: updateItem.lastname ? updateItem.lastname : '',
                      phone: updateItem.phone ? updateItem.phone : '',
                      email: updateItem.email ? updateItem.email : '',
                      address: updateItem.address ? updateItem.address : '',
                      locstate: updateItem.location_state ? updateItem.location_state : '',
                      country: updateItem.location_country ? updateItem.location_country : '',                      
                      role: updateItem.role ? updateItem.role : ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm, setShowModal });
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit} encype="multipart/form-data">
                        <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group my-2'>
                            <div>
                              <label htmlFor="firstname">First name</label>
                            </div>
                            <Field
                              id="firstname"
                              name="firstname"
                              value={values.firstname}
                              onChangeText={handleChange('firstname')}
                              placeholder="First name"
                              placeholderTextColor="#999"
                              onBlur={handleBlur('firstname')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                            {touched.firstname && errors.firstname && <div className='py-1'>
                                  <span className='text-danger'>{touched.firstname && errors.firstname}</span>
                            </div>}
                          </div>

                          <div className='form-group my-2'>
                            <div>
                              <label htmlFor="lastname">Last name</label>
                            </div>
                            <Field
                              id="lastname"
                              name="lastname"
                              value={values.lastname}
                              onChangeText={handleChange('lastname')}
                              placeholder="Last name"
                              placeholderTextColor="#999"
                              onBlur={handleBlur('lastname')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                            {touched.lastname && errors.lastname && <div className='py-1'>
                                  <span className='text-danger'>{touched.lastname && errors.lastname}</span>
                            </div>}
                          </div>

                          <div className='form-group my-1'>
                            <div>
                              <label htmlFor="email">Email</label>
                            </div>
                            <Field
                              id="email"
                              name="email"
                              value={values.email}
                              placeholder="Your email"
                              onBlur={handleBlur('email')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                          {touched.email && errors.email && <div className='py-1'>
                                <span className='text-danger'>{touched.email && errors.email}</span>
                          </div>}
                        </div>



                        <div className='form-group my-1'>
                            <div>
                              <label htmlFor="phone">Phone</label>
                            </div>
                            <Field
                              disabled
                              id="phone"
                              name="phone"
                              value={values.phone}
                              placeholder="Phone"
                              onBlur={handleBlur('phone')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                              {touched.phone && errors.phone && <div className='py-1'>
                                  <span className='text-danger'>{touched.phone && errors.phone}</span>
                              </div>}
                            </div>


                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="username">Username</label>
                          </div>
                            <Field
                              disabled
                              id="username"
                              name="username"
                              value={values.username}
                              placeholder="Username"
                              onBlur={handleBlur('username')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                          {touched.username && errors.username && <div className='py-1'>
                                <span className='text-danger'>{touched.username && errors.username}</span>
                          </div>}
                        </div>


                          </div>
                  <div className='col-md-6'>
                    
                  <div className='form-group my-1'>
                          <div>
                            <label htmlFor="address">Address</label>
                          </div>
                            <Field
                              as='textarea'
                              id="address"
                              name="address"
                              value={values.address}
                              placeholder="Address"
                              onBlur={handleBlur('address')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                          {touched.address && errors.address && <div className='py-1'>
                                <span className='text-danger'>{touched.address && errors.address}</span>
                          </div>}
                    </div>
                    
                    
                    <div className='form-group my-2'>
                          <div className='p-2 h6'>
                            <label htmlFor="locstate">State</label>
                          </div>
                          <Field
                            id="locstate"
                            name="locstate"
                            value={values.locstate}
                            onBlur={handleBlur('locstate')}
                            autoCapitalize="none"
                            style={{
                              padding: 10
                          }}
                          className="form-control"
                          />
                        {touched.locstate && errors.locstate && <div className='py-1'>
                              <span className='text-danger'>{touched.locstate && errors.locstate}</span>
                        </div>}
                      </div>


                  <div className='form-group my-2'>
                          <div className='p-2 h6'>
                            <label htmlFor="country">Country</label>
                          </div>
                            <Field
                              as='select'
                              id="country"
                              name="country"
                              value={values.country}
                              onBlur={handleBlur('country')}
                              autoCapitalize="none"
                              style={{
                                padding: 10
                            }}
                            className="form-control"
                            >
                              <option value="">Select country...</option>
                              {africancountries.map((item,i) => {
                                return <option key={item.id} value={item.name}>{item.name}</option>
                              })}
                            </Field>
                            
                            {touched.country && errors.country && <div className='py-1'>
                                <span className='text-danger'>{touched.country && errors.country}</span>
                          </div>}
                        </div>
          


                          </div>
                        </div>
                        <button
                            type='submit'
                            buttonType="outline"
                            onClick={handleSubmit}
                            title="Update"
                            disabled={!isValid || isSubmitting}
                            loading={isSubmitting}
                              className='btn btn-primary'
                          >
                              Update
                          </button>
                        
                          </form>
                    )}
                  </Formik>
      </div>
    </div>
    )
}

export default UpdateSignupForm;