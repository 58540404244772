import React, { useState, useEffect, useContext } from 'react';
import { Pagination, NavigationBar } from '../components';
import moment from 'moment';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { SiteContext } from '../context';
import { Seo, Header, Footer } from './';
import { Tabs, Tab } from 'react-bootstrap';

const BlogCatYear = () => {

    let navigate = useNavigate();

    const { apiUrl, userData, isMobile, siteconfig } = useContext(SiteContext);

    const [articleYears, setArticleYears] = useState([]);
    const [articleCats, setArticleCats] = useState([]);
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [key, setKey] = useState('Category');

    useEffect(() => {
        getCatYear();
    }, [])

    const getCatYear = () => {
        setLoading(true);

        fetch(`${apiUrl}/api/articles.php?action=fetchblogcatyear`, {
            method: 'get'
        }).then((res) => {
            return res.json()
        }).then((res) => {
            if (res.status === 200) {
                if (res.years.length > 0 || res.artcats.length > 0) {

                    setArticleYears(res.years);
                    setArticleCats(res.artcats);
                    setLoading(false);

                }
            } else if (res.status === 400) {
                setLoading(false);
                setErrorMsg(res.msg)
                setTimeout(() => {
                    setErrorMsg('')
                }, 3000)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <div className='bg-light shadow rounded p-3'>
            <h5 className='py-2 my-2'>Posts by</h5>
            <Tabs defaultActiveKey={key} onSelect={(k) => setKey(k)}>
                {articleCats.length > 0 && <Tab eventKey='Category' title='Category'>
                    <div className='rounded p-2'>
                        {articleCats.map((articleCat, i) => {
                            return (
                                <Link className='text-decoration-none' to={`/post-category/${articleCat.artcategory.replace(/\s/gi, '-').toLowerCase()}`}>
                                    <h6 className='p-1 text-dark'>{`${articleCat.artcategory} (${articleCat.totalcat})`}</h6>
                                </Link>
                            )
                        })}
                    </div>

                </Tab>}
                {articleYears.length > 0 && <Tab eventKey='Year' title='Year'>
                    <div className='rounded p-2'>
                        {articleYears.map((articleYear, i) => {
                            return (
                                <Link className='text-decoration-none' to={`/post-year/${articleYear.artyear}`}>
                                    <h6 className='p-1 text-dark'>{`${articleYear.artyear} (${articleYear.totalyear})`}</h6>
                                </Link>
                            )
                        })}
                    </div>

                </Tab>}
            </Tabs>
            {loading && <div className='d-flex justify-content-center align-content-center my-5'>
                <div class="spinner-border text-secondary" role="status" />
            </div>}

        </div>
    )
}

export default BlogCatYear;