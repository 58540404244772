import React, { useState, useEffect, useContext } from 'react';
import { Header, Footer } from './';
import { Pagination, NavigationBar } from '../components';
import { useNavigate, Link } from 'react-router-dom';
import { DashboardMenu } from '../components';
import { SiteContext } from '../context';
import ReactModal from 'react-modal';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    country: Yup.string()
      .label('Country')
      .required()
      .min(3, 'Must have at least 3 characters'),
    amount: Yup.string()
      .label('Amount')
      .required()
      });


const Shipping = () => {

  let navigate = useNavigate();

  const { apiUrl, isMobile, formatAmount } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem('user'));

  const [shipping, setShipping] = useState([]);
  const [mode, setMode] = useState('');
  const [total, setTotal] = useState(null);
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [cangoBack, setCangoBack] = useState(true);
  const [cangoForward, setCangoForward] = useState(true);
  const [updateItem, setUpdateItem] = useState({});
  const [itemSelected, setItemSelected] = useState('');
  const [msg, setMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showModalShipping, setShowModalShipping] = useState(false);

  useEffect(() => {
    getShipping();
  }, [page, limit])

  useEffect(() => {
    getShipping();
  })
    
  const getShipping = () => {
    fetch(`${apiUrl}/api/shipping.php?action=fetch&role=${userData.role}&page=${page}&limit=${limit}`, {
      method: 'get'
    }).then((res) => {
      return res.json()
    }).then((res) => {

      if (res.status === 200) {
        if (res.rows.length > 0) {
          setShipping(res.rows);
          setTotal(res.rows.length);
        }
      } else if (res.status === 400) {
        setErrorMsg(res.msg)
        setTimeout(() => {
          setErrorMsg('')
        }, 5000)
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleSubmit = (values, { setSubmitting, resetForm, setShowModalShipping }) => {

    let formData = new FormData();
  
    formData.append('shippingcountry', values.country);
    formData.append('shippingstate', values.state);
    formData.append('shippingrate', values.amount);
    formData.append('shid', values.id);
    formData.append('action', mode);
  
    const url = `${apiUrl}/api/shipping.php`;
    const sendType = 'post';
  
      return fetch(url, {
        method: sendType,
        body: formData
      })
        .then((res) => res.json())
        .then((res) => {

          if(res.status === 200) {
            setMsg(res.msg);
            resetForm({});
            setSubmitting(false);      
            setTimeout(() => {
                setMsg('');
                setShowModalShipping(false);
              }, 3000);
            setTimeout(() => {
                getShipping();
            },4000);
          } else if(res.status === 400) {
            setSubmitting(false);
            setErrorMsg(res.msg);
            setTimeout(() => {
              setErrorMsg('');
            },4000);
          } else {
            alert(
                  'Failure',
                  'Error connection to the server please check your details and try again',
                  [{ text: 'Okay' }]
                );
          }
        })
        .catch((error) => {
          console.log('Api call error', error.message);
        });
    };
  

  const doPrevious = async () => {

    if (parseInt(page) > 1) {
      await setPage(() => parseInt(page - 1));
      setCangoBack(true);
      setCangoForward(true);
    } else {
      setCangoBack(false);
      setCangoForward(true);
    }
  }

  const doNext = async () => {

    if (parseInt(page * limit) < total) {
      await setPage(() => parseInt(page + 1));
      setCangoForward(true);
      setCangoBack(true);
    } else {
      setCangoForward(false);
      setCangoBack(true);
    }
  }

  const handleChange = (event) => {
    let newLimit = event.target.value;
    setLimit(newLimit);
  }

  const handleDelete = (id) => {
    setItemSelected(id);
    setShowModal(true);
  }


  const doDelete = (itemSelected) => {
    setShowModal(false);

    let formData = new FormData();

    formData.append('shid', itemSelected);
    formData.append('role', userData.role);
    formData.append('action', 'delete');

    fetch(`${apiUrl}/api/shipping.php`, {
      method: 'post',
      body: formData
    }).then((res) => {
      return res.json()
    }).then((res) => {
      if (res.status === 200) {
        setMsg(res.msg);
        getShipping();
        setTimeout(() => {
          setMsg('');
        }, 3000);
          } else if (res.status === 400) {
        setErrorMsg(res.msg)
        setTimeout(() => {
          setErrorMsg('')
        }, 3000);
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleAction = (type,obj) => {

      if(type === 'add') {
        setMode('create');
        setUpdateItem({});
        setShowModalShipping(true);
      } else {
          setMode('update');
          setUpdateItem(obj);
          setShowModalShipping(true);
      }
  }

  return (
    <div>
      <ReactModal
        isOpen={showModal}
        style={{
          overlay: {
            flex: 1,
            justifyContent: 'center',
            alignContent: 'center',
            position: 'fixed',
            top: '10%',
            bottom: '10%',
            left: isMobile ? '10%' : '25%',
            right: isMobile ? '10%' : '25%',
            width: isMobile ? '80%' : '50%',
            height: 250,
            backgroundColor: 'rgba(255, 255, 255, 0.75)'
          },
          content: {
            position: 'absolute',
            top: '20px',
            left: '20px',
            right: '20px',
            bottom: '20px',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px'
          }
        }}>
        <div>
          <p className='h3 my-1 py-1'>Confirm deletion</p>
          <p className='my-1 py-2'>Action cannot be reversed!</p>
          <span className='btn btn-primary' onClick={() => doDelete(itemSelected)}>Confirm</span> <span className='btn btn-secondary' onClick={() => setShowModal(false)}>Cancel</span>
        </div>
      </ReactModal>

      <ReactModal
              isOpen={showModalShipping}
              style={{
                overlay: {
                  flex: 1,
                  justifyContent: 'center',
                  alignContent: 'center',
                  position: 'fixed',
                  top: '20%',
                  bottom: '10%',
                  left: isMobile ? '10%' : '25%',
                  right: isMobile ? '10%' : '25%',
                  width: isMobile ? '80%' : '50%',
                  height: '70%',
                  backgroundColor: 'rgba(255, 255, 255, 0.75)'
                },
                content: {
                  position: 'absolute',
                  top: '20px',
                  left: '20px',
                  right: '20px',
                  bottom: '20px',
                  border: '1px solid #ccc',
                  background: '#fff',
                  overflow: 'auto',
                  WebkitOverflowScrolling: 'touch',
                  borderRadius: '4px',
                  outline: 'none',
                  padding: '20px'
                }
              }}>
              <div>
      
      <Formik
                    initialValues={{
                      id: updateItem.shippingid ? updateItem.shippingid : '',
                      country: updateItem.shippingcountry ? updateItem.shippingcountry : '',
                      state: updateItem.shippingstate ? updateItem.shippingstate : '',
                      amount: updateItem.shippingcost ? updateItem.shippingcost : '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                      setTimeout(() => {
                        setShowModalShipping(false);
                      },1000);
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit}  encype="multipart/form-data">
                        <div className='my-2'>
                        
                          <div className='form-group my-2'>
                          <div>
                            <label htmlFor="country">Country</label>
                          </div>
                          <Field
                            id="country"
                            name="country"
                            value={values.country}
                            placeholder="Country"
                            onBlur={handleBlur('country')}
                            autoCapitalize="none"
                            style={{
                              padding: 4
                          }}
                          className="form-control"
                          />
                          {touched.country && errors.country && <div className='py-1'>
                                <span className='text-danger'>{touched.country && errors.country}</span>
                          </div>}
                        </div>

                        <div className='form-group my-2'>
                          <div>
                            <label htmlFor="state">State</label>
                          </div>
                          <Field
                            id="state"
                            name="state"
                            value={values.state}
                            placeholder="State"
                            onBlur={handleBlur('state')}
                            autoCapitalize="none"
                            style={{
                              padding: 4
                          }}
                          className="form-control"
                          />
                          {touched.state && errors.state && <div className='py-1'>
                                <span className='text-danger'>{touched.state && errors.state}</span>
                          </div>}
                                  </div>
                                  
                        <div className='form-group my-2'>
                          <div>
                            <label htmlFor="amount">Amount</label>
                          </div>
                          <Field
                            id="amount"
                            name="amount"
                            value={values.amount}
                            placeholder="Amount"
                            onBlur={handleBlur('amount')}
                            autoCapitalize="none"
                            style={{
                              padding: 4
                          }}
                          className="form-control"
                          />
                          {touched.amount && errors.amount && <div className='py-1'>
                                <span className='text-danger'>{touched.amount && errors.amount}</span>
                          </div>}
                        </div>

                              </div>
                         
                         
                          <button
                            type='submit'
                            onClick={handleSubmit}
                            title={mode}
                            disabled={!isValid || isSubmitting}
                              className='btn btn-primary my-2'
                          >
                              {mode.charAt(0).toUpperCase()+''+mode.slice(1)}
                          </button>

                          
                    <button
                      onClick={() => setShowModalShipping(false)}
                      title="Cancel"
                      className='btn btn-secondary mx-2'
                    >
                      Cancel
                    </button>
                        
                          </form>
                    )}
                  </Formik>
                  </div>
      </ReactModal>
      <Header />
      <div>
        <div className='row'>
          <div className='col-md-2 bg-light'>
            <DashboardMenu />
          </div>
          <div className='col-md-10'>
            <div className='container my-2'>
            <NavigationBar section={'Shipping'} goback={true} />

              <h3>Shipping | <Link to='/adminproducts'>Products</Link></h3>
              <div className='my-2 d-flex justify-content-between'>
                <div className='align-items-center'>
                  {msg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{msg}</span></div> : null}
                  {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                </div>
                <div>
                  <button className='btn btn-primary' onClick={() => handleAction('add',{})}>New</button>
                </div>
              </div>

              {shipping.length > 0 && <Pagination limit={limit} page={page} total={total} cangoBack={cangoBack} cangoForward={cangoForward} handleChange={handleChange} doNext={doNext} doPrevious={doPrevious} />}

              {shipping.length > 0 &&
                <div className='container p-3'>
                  <div className='row border p-1 bg-light'>
                    <div className='col-1'>No</div>
                    <div className='col-3'>Country</div>
                    <div className='col-3'>State</div>
                    <div className='col-2 text-end'>Amount</div>
                    <div className='col-3'>Action</div>
                  </div>
                  {shipping.map((item, i) => {
                    return (
                      <div key={i} className='row border-bottom py-1'>
                        <div className='col-1'>{++i}</div>
                        <div className='col-3'>{item.shippingcountry}</div>
                        <div className='col-3'>{item.shippingstate}</div>
                        <div className='col-2 text-end'>{formatAmount(item.shippingcost)}</div>
                        <div className='col-3'><a onClick={() => handleAction('update', item)}><i className='bi bi-pencil text-dark h4'></i></a> <a onClick={() => handleDelete(item.shippingid)}><i className='bi bi-trash text-danger h4'></i></a> </div>
                      </div>
                    )
                  })}
                </div>}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Shipping;