import { SiteContextProvider } from './context';
import SiteRoutes from './navigation/SiteRoutes';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

const initialOptions = {
  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: "GBP"
}

function App() {
  return (
      <SiteContextProvider>
        <SiteRoutes />
      </SiteContextProvider>
  );
}

export default App;
