import React, {useState, useEffect, useContext} from 'react';
import {Header, Footer} from './';
import { DashboardMenu, Pagination } from '../components';
import { SiteContext } from '../context';
import moment from 'moment';
import ReactModal from 'react-modal';

const DriveRequests = () => {

const { apiUrl, singleProduct } = useContext(SiteContext);

const userData = JSON.parse(localStorage.getItem('user'));

const [orderrequests, setOrderRequests] = useState([]);
const [total, setTotal] = useState(null);
const [limit, setLimit] = useState(10);
const [page, setPage] = useState(1);
const [cangoBack, setCangoBack] = useState(true);
const [cangoForward, setCangoForward] = useState(true);
const [itemSelected, setItemSelected] = useState('');
const [msg, setMsg] = useState('');
const [errorMsg, setErrorMsg] = useState('');
const [showModal, setShowModal] = useState(false);

useEffect(() => {
  getOrderRequests();
},[page, limit])

const getOrderRequests = () => {
  fetch(`${apiUrl}/api/driverrequests.php?action=fetch&page=${page}&limit=${limit}&role=${userData.role}`, {
    method:'get'
  }).then((res) => {
      return res.json()
  }).then((res) => {
      if(res.rows.length > 0) {
          setOrderRequests(res.rows);
          setTotal(res.count);      
      } else if (res.rows.length === 0) {
          setMsg('No driver requests found')
          setTimeout(() => {
            setMsg('')
          },3000)
      }
  }).catch((err) => {
      console.log(err)
  })
}

const doPrevious = async () => {
 
  if(parseInt(page) > 1) {
    await setPage(() => parseInt(page - 1));
    setCangoBack(true);
    setCangoForward(true);
  } else {
    setCangoBack(false);
    setCangoForward(true);
  }
}

const doNext = async () => {

  if(parseInt(page * limit) < total) { 
    await setPage(() => parseInt(page + 1));
    setCangoForward(true);
    setCangoBack(true);
  } else {
    setCangoForward(false);
    setCangoBack(true);
  }
}

const handleChange = (event) => {
  let newLimit = event.target.value;
  setLimit(newLimit);
}


const handleDelete = (orderref) => {
    setItemSelected(orderref);
    setShowModal(true);
  }


const doDelete = orderref => {
    setItemSelected('');
    setShowModal(false);

    fetch(`${apiUrl}/api/driverrequests.php?action=delete&role=${userData.role}&orderref=${orderref}`, {
      method:'get'
    }).then((res) => {
        return res.json()
    }).then((response) => {
      if(response.status === 200) {
        getOrderRequests();
        setMsg(response.msg)
        setTimeout(() => {
          setMsg('')
        },3000)  
      } else if (response.status === 400) {
        setErrorMsg(response.msg)
        setTimeout(() => {
          setErrorMsg('')
        },3000)  
      }
    }).catch((err) => {
        console.log(err)
    })
  }


    return (
        <div>
            <ReactModal
            isOpen={showModal}
            style={{
                overlay: {
                flex:1,
                justifyContent: 'center',
                alignContent: 'center',
                position: 'fixed',
                top: '10%',
                bottom: '10%',
                left: '25%',
                right: '25%',
                width: '50%',
                height: 250,
                backgroundColor: 'rgba(255, 255, 255, 0.75)'
                },
                content: {
                position: 'absolute',
                top: '20px',
                left: '20px',
                right: '20px',
                bottom: '20px',
                border: '1px solid #ccc',
                background: '#fff',
                overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                borderRadius: '4px',
                outline: 'none',
                padding: '20px'
                }
            }}>
                <div>
                <p className='h3 my-1 py-1'>Confirm deletion</p>
                <p className='my-1 py-2'>Action cannot be reversed!</p>
                <span className='btn btn-primary' onClick={() => doDelete(itemSelected)}>Confirm</span> <span className='btn btn-secondary' onClick={() => setShowModal(false)}>Cancel</span>
                </div>
            </ReactModal>
            <Header />
            <div>
                <div className='row'>
                    <div className='col-md-2 bg-light'>
                        <DashboardMenu />
                    </div>
                    <div className='col-md-10'>
                    <div className='container my-2'>
                <h3>Requests to drivers</h3>
                <div className='my-2 d-flex justify-content-between'>
                  <div className='align-items-center'>
                  {msg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{msg}</span></div> : null}
                {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                  </div>
                </div>

                <Pagination limit={limit} page={page} total={total} cangoBack={cangoBack} cangoForward={cangoForward} handleChange={handleChange} doNext={doNext} doPrevious={doPrevious} />

                        <div className='container p-3'>
                            <div className='row border'>
                                <div className='col-1'>No</div>
                                <div className='col-3'>Order</div>
                                <div className='col-3'>Customer</div>
                                <div className='col-2'>Driver</div>
                                <div className='col-2'>Status</div>
                                <div className='col-1'>Action</div>
                            </div>
                            {orderrequests.map((ordreq,i) => {
                                return (
                                <div key={i} className='row small border-bottom p-2'>
                                <div className='col-1'>{++i}</div>
                                <div className='col-3'>{moment(ordreq.createdAt).format('Do MMM YYYY, h:mma')}<br />
                                {singleProduct ? ordreq.productname : ordreq.orderdetails && JSON.parse(ordreq.orderdetails).map((item,i) => <div key={item.id}>{item.item} ({item.qty})</div>)}<br />
                                </div>
                                <div className='col-3'>{ordreq.customername}<br />
                                {ordreq.customerphone}<br />
                                </div>
                                <div className='col-2'>{ordreq.drivername}<br />
                                {ordreq.driverphone}<br />
                                {ordreq.driver && moment(ordreq.updatedAt).format('h:mma, Do MMM')} <br />
                                </div>
                                <div className='col-2'>{ordreq.status}</div>
                                <div className='col-1'><a onClick={() => handleDelete(ordreq.orderref)}><i className='bi bi-trash text-danger h4'></i></a>
                                </div>
                                </div>  
                                )
                            })}
                            </div>
                      </div>
                    </div>
                </div>
            </div>
          <Footer />
        </div>
    )
}

export default DriveRequests;