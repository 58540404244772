import React, {useState, useEffect, useContext} from 'react';
import {Header, Footer} from './';
import { DashboardMenu, PayButton, NavigationBar, PaypalCheckoutButton } from '../components';
import moment from 'moment';
import { Formik, Field } from 'formik';
import { useParams } from 'react-router-dom';
import { SiteContext } from '../context';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    orderstatus: Yup.string()
      .label('Status')
      .required(),
  });
  

const TransactionDetail = () => {

const { apiUrl, userData, formatAmount, handleAvailability, singleProduct } = useContext(SiteContext);

// const userData = JSON.parse(localStorage.getItem('user'));

let {orderref} = useParams();

const [order, setOrder] = useState({});
const [orderedItems, setOrderedItems] = useState([]);
const [msg, setMsg] = useState('');
const [errorMsg, setErrorMsg] = useState('');

useEffect(() => {
  getOrder();
},[])

const getOrder = () => {
  fetch(`${apiUrl}/api/orders.php?action=fetchsingle&orderref=${orderref}`, {
    method:'get'
  }).then((res) => {
      return res.json()
  }).then((res) => {
    if(res.status === 200) {
          setOrder(res.order);
          setOrderedItems(JSON.parse(res.order.orderdetails));
      } else if(res.status === 400){
          setMsg(res.msg);
          setTimeout(() => {
            setMsg('');
          },5000)
      }
  }).catch((err) => {
      console.log(err)
  })
}


const doCustomerUpdate = orderref => {
    fetch(`${apiUrl}/api/orders.php?action=customerupdate&user=${userData.id}&orderref=${orderref}`, {
      method:'get'
    }).then((res) => {
        return res.json()
    }).then((res) => {
      if(res.status === 200) {
        setOrder(res.orderdetail)
        setMsg(res.msg)
        setTimeout(() => {
          setMsg('')
        },3000)
      } else if(res.status === 400) {
        setErrorMsg(res.msg)
        setTimeout(() => {
          setErrorMsg('')
        },3000)  
      }
    }).catch((err) => {
        console.log(err)
    })
  }


  const doCustomerPaymentUpdate = orderref => {
    fetch(`${apiUrl}/api/orders.php?action=customerpayment&user=${userData.id}&orderref=${orderref}`, {
      method:'get'
    }).then((res) => {
        return res.json()
    }).then((res) => {
      if(res.status === 200) {
        setOrder(res.orderdetail);
        setMsg(res.msg)
        setTimeout(() => {
          setMsg('')
        },3000)  
      } else if (res.status === 400) {
        setErrorMsg(res.msg)
        setTimeout(() => {
          setErrorMsg('')
        },3000)  
      }
    }).catch((err) => {
        console.log(err)
    })
  }

  const handleSubmit = (values, { setSubmitting, resetForm, setShowModal, selectedProduct, selectedUser }) => {

    let formData = new FormData();
  
    formData.append('status', values.orderstatus);
    formData.append('role', userData.role);
    formData.append('orderref', order.orderref);
    formData.append('action', 'orderupdate');
  
      return fetch(`${apiUrl}/api/orders.php`, {
        method: 'post',
        body: formData
      })
          .then((res) => res.json())
        .then(async (res) => {
  
          console.log(res);

          if(res.status === 200) {
              resetForm({});
              setSubmitting(false);
              setOrder(res.orderdetail);
              setMsg(res.msg);
              setTimeout(() => {
                setMsg('');
              }, 3000);
          } else if(res.status === 400) {
            setErrorMsg(res.msg);
            setTimeout(() => {
              setErrorMsg('');
            }, 5000);
            setSubmitting(false);
          } else {
            alert(
                  'Order placement failed',
                  'Error connection to the server please check your details and try again',
                  [{ text: 'Okay' }]
                );
          }
        })
        .catch((error) => {
          console.log('Api call error', error.message);
        });
    };
  

  return (
        <div>
            <Header />
            <div>
                <div className='row'>
                    <div className='col-md-2 bg-light'>
                        <DashboardMenu />
                    </div>
                    <div className='col-md-10'>
                    <div className='container my-2'>
                      
                      <NavigationBar section={'Orders'} goback={true} />

                <h3>Order detail</h3>

                <div className='align-items-center'>
                  {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                </div>

                {order.hasOwnProperty('orderref') && 
                
                <div>

                <div className='row'>
                  <div className='col-md-6 my-3'>
                    Order Ref: {order.orderref}<br />
                    Item{orderedItems.length > 1 && 's'}: {singleProduct ? order.productname : orderedItems.map((item,i) => <div className='small' key={item.id}>{item.item} x {item.qty} {formatAmount(item.total)}</div>)}<br />
                    Coupon: {order.order_currency}{order.coupon}<br />
                    Shipping: {order.order_currency}{order.shipping}<br />
                    Total amount: {order.order_currency}{order.amount}<br />
                    Order time: {moment(order.createdAt).format('Do MMM YYYY h:mma')}<br />
                  </div>
                  <div className='col-md-6 my-3'>
                    Transaction status: {order.status} {userData.role === 'admin' && <Formik
                    initialValues={{
                      orderref: order.orderref,
                      orderstatus: order.status,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit} encype="multipart/form-data">
                        <div className='row'>
                          <div className='col-md-6'>


                        <div className='form-group my-1'>
                          <div className='row'>
                            <div className='col-6'>
                            <Field
                              as='select'
                              id="orderstatus"
                              name="orderstatus"
                              value={values.orderstatus}
                              onChangeText={handleChange('orderstatus')}
                              placeholder="State of orderstatus"
                              placeholderTextColor="#999"
                              onBlur={handleBlur('orderstatus')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            >
                              <option value="">Select...</option>
                              <option value="New" name="New">New</option>
                              <option value="Processed" name="Processed">Processed</option>
                              <option value="Cancelled" name="Cancelled">Cancelled</option>
                              <option value="Completed" name="Completed">Completed</option>
                            </Field>

                          {touched.orderstatus && errors.orderstatus && <div className='py-1'>
                                <span className='text-danger'>{touched.orderstatus && errors.orderstatus}</span>
                          </div>}

                            </div>
                            <div className='col-6'>
                            <button
                            type='submit'
                            buttonType="outline"
                            onClick={handleSubmit}
                            title="Update"
                            disabled={!isValid || isSubmitting}
                            loading={isSubmitting}
                              className='btn btn-primary btn-sm'
                          >
                              Update
                          </button>

                            </div>
                          </div>
                        </div>

                          </div>
                        </div>
                        
                          </form>
                    )}
                  </Formik>}<br />
                    Payment status: {order.paymentstatus} <br />
                    {userData && userData.id === order.customerid && order.paymentstatus === 'Unpaid' && order.paymentmode === 'Card' && <PaypalCheckoutButton product={{description: JSON.stringify(orderedItems.map((it,i) => `${it.item} ${it.qty} ${it.price}<br />`))+ ' Shipping - ' +order.shipping, price: order.amount}} doCustomerPayment={doCustomerPaymentUpdate} orderref={order.orderref} />}
                  </div>
                  <div className='col-md-6'>
                    <h5>Customer</h5>
                    Name: {order.customerfirstname} {order.customerlastname}<br />
                    Phone: {order.customerphone}<br />
                    Address: {order.customeraddress}<br />
                    Delivery: {order.customerdeliverystatus} {userData.id === order.customerid && order.customerdeliverystatus !== 'Delivered' && <button className='bg-success bg-opacity-75 text-white btn-sm px-2 py-1 rounded' onClick={() => doCustomerUpdate(order.orderref)}>Confirm delivery</button>}<br />
                    {order.customerdeliverystatus === 'Delivered' ? <div>Delivery time: {moment(order.customerdeliverystatustime).format('Do MMM YYYY h:mma')}</div> : ''}
                  </div>
                  <div>

                  </div>
                </div>

                </div>}

            </div>
                    </div>
                </div>
            </div>
          <Footer />
        </div>
    )
}

export default TransactionDetail;