import React, { useState, useEffect, useContext, useRef, useMemo, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Comments, DoQuantity } from '.';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { SiteContext } from '../context';

const Item = ({item}) => {

    const { userData, apiUrl, isMobile, getItemTotal, handleSubmit, siteMaxWidth, formatAmount, cart, manageCartNew, singleProduct } = useContext(SiteContext);

    const [msg, setMsg] = useState('');
  const [canOrder, setCanOrder] = useState(false);
  const [showImg, setShowImg] = useState(false);
  const [imgSelected, setImgSelected] = useState('');
    const [key, setKey] = useState('Description');

    const {slug} = useParams();
  let navigate = useNavigate();

  // useEffect(() => {
  //   setCartContents(cart);
  //   cart.length > 0 && setCanOrder(true);
  // },[cart]);


  // const getItemTotal = (id) => {
  //     const cartItems = cart.slice();
  //     const item = cartItems.filter((a) => a.id == id);

  //     if(item.length > 0) {
  //       return item[0].qty;
  //     } else {
  //       return '0';
  //     }
  // }
  
  const handleImgSelected = (url) => {
    setImgSelected(url);
    setShowImg(true);
  }

    
    return (
      <div>
        {showImg && 
                <div style={{ 'position': 'fixed', 'top': 0, 'left': 0, 'zIndex': 400 }} className='w-100 h-100 bg-dark bg-opacity-75' />
            }

        {showImg ?
          <div style={isMobile ? { 'backgroundColor': '#ffffff', 'overflow': 'hidden', 'position': 'fixed', 'top': '10%', 'bottom': '10%', 'left': '5%', 'right': '5%', 'width': '90%', 'height': '80%', 'zIndex': 500 } : { 'backgroundColor': '#ffffff', 'overflow': 'hidden', 'position': 'fixed', 'top': '15%', 'bottom': '15%', 'width': '60%', 'height': '70%', 'left': '20%', 'right':'20%', 'zIndex': 500 }} className={'row'} >
            <div className='p-5 text-center'>
            <img className='text-center' style={{ 'position': 'relative', 'zIndex': 90, 'objectFit':'cover'}} src={`${imgSelected}`} alt='product image' />
                <div style={{'position':'absolute', 'zIndex': 100, 'right':10, 'top': 10}} className='m-1 p-1'>
                    <button onClick={() => setShowImg(false)}><span className='p-2 bg-dark text-white rounded-circle'>X</span></button>
                </div>
            </div>
          </div> :  <div style={{'position':'fixed', 'top':0, 'left': '-100%', 'zIndex':400}} className='w-100 h-100 bg-dark bg-opacity-75'>
        </div>}


        {item.hasOwnProperty('id') && <div className='my-1 py-1'>

        <div className='container mt-3 px-4 py-2 bg-light'>
            <Link className='text-decoration-none' to='/'>Home</Link> / <Link className='text-decoration-none' to='/products'>Products</Link> / <span>{item.name}</span>  
        </div>

        {msg !== '' ? <div className='my-2'><span className='p-2 my-4 border border-info text-dark'>{msg}</span></div> : null}

            <div className='p-1'>
                <div className='row rounded m-1 p-1'>
              <div className='col-md-7'>
                <div className='row'>
                  <div className='col-12'>
                    {item.picture && <img onClick={() => handleImgSelected(`${apiUrl}/${item.picture}`)} className='img-fluid cursor-pointer rounded my-1 mr-2' src={`${apiUrl}/${item.picture}`} height={250} width='100%' alt={item.name} />}
                  </div>
                  <div className='col-6'>
                    {item.picture2 && <img onClick={() => handleImgSelected(`${apiUrl}/${item.picture2}`)} className='img-fluid cursor-pointer rounded my-1 mr-2' src={`${apiUrl}/${item.picture2}`} height={250} width='100%' alt={item.name} />}
                  </div>
                  <div className='col-6'>
                    {item.picture3 && <img onClick={() => handleImgSelected(`${apiUrl}/${item.picture3}`)} className='img-fluid cursor-pointer rounded my-1 mr-2' src={`${apiUrl}/${item.picture3}`} height={250} width='100%' alt={item.name} />}
                    </div>
                  </div>
                    </div>
                    <div className='col-md-5'>
                            <p className='h3'>{item.name}</p>
                            {item.size !== '' && <p className='sm'><span className='h6'>Weight:</span> {item.size}</p>}
                            {item.sku !== '' && <p className='sm'><span className='h6'>SKU:</span> {item.sku}</p>}
                            <p className='sm'>{item.qty >= 1 ? `Available (${item.qty})` : <span className='p-2 bg-dark rounded text-white'>Sold out</span>}</p>
                            <p className='h5'> {formatAmount(item.price)}</p>    
                {item.qty >= 1 && <DoQuantity itemid={item.id} />}

                <div className='my-3 py-2'>
                      <Tabs defaultActiveKey={key} onSelect={(key) => setKey(key)}>
                        <Tab eventKey={'Description'} title='Description'>
                          {item.hasOwnProperty('description') && <div dangerouslySetInnerHTML={{__html: item.description.replace(/(\r\n|\r|\n)/g, '<br />')}} />}

                        </Tab>
                        <Tab eventKey={'Reviews'} title='Reviews'>
                          <Comments slug={item.slug} section='Products' />
                        </Tab>
                      </Tabs>
                    </div>

                            {/* {singleProduct ? <button className='btn p-2 bg-dark text-white' onClick={() => setCanOrder(true)}>Order now</button> : <p className='h5'>Qty: <button className='btn btn-white border border-dark' onClick={() => manageCart('-', item.id, item.name, item.price)}><span className='h6'>-</span></button> <button  className='btn btn-white border border-dark'><span className='h6'>{getItemTotal(item.id)}</span></button> <button  className='btn btn-white border border-dark' onClick={() => manageCart('+', item.id, item.name, item.price)}><span className='h6'>+</span></button></p>}     */}
                    </div>
                </div>    
            </div>


            </div>} 

        </div>
    )
}

export default Item;