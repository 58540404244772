import React, {useContext} from 'react';
import { NavigationBar } from '../components';
import { Link } from 'react-router-dom';
import { SiteContext } from '../context';

const JobCategory = () => {

const { apiUrl, categories } = useContext(SiteContext);

    return (
        <div>
              <NavigationBar section={'Category'} goback={false} />

              <div className='p-2'>
                  <div className='row'>
                   {categories.map((item,i) => {
                  return (
                        <div key={i} className='col-md-4 col-6'>
                            <Link className='text-dark text-decoration-none' to={`/categories/category/${item.jcatslug}`}>
                                <div className='card p-2 my-2'>
                                    <div className='p-2 card-img'>
                                        <img style={{'objectFit':'cover'}} src={`${apiUrl}/${item.jcatphoto}`} height={150} width='100%' alt={item.jcatname} />
                                    </div>
                                    <div className='text-center bg-dark text-white opacity-25 my-0 h5 card-img-overlay' />                                    
                                    <div className='p-1 text-center bg-dark text-white rounded'>{item.jcat}</div>                                    
                                </div>
                            </Link>
                        </div>
                  )
              })}
                </div>
            </div>
        </div>
    )
}

export default JobCategory;