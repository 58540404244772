import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SiteContext } from '../context';

const RandomProducts = () => {

    const { apiUrl, formatAmount, products } = useContext(SiteContext);

    let navigate = useNavigate();

    const newArray = (arr, num) => {
        const shuffled = [...arr].sort(() => 0.5 - Math.random());

        return shuffled.slice(0,num)
    };

    const randomArray = newArray(products, 4);

    return (
        <div className='row my-2 py-2'>
            <h2 className='my-1 py-2'>You may also like...</h2>
           {randomArray.map((item,i) => {
                                return (
                                    <Link key={i} to={`/products/detail/${item.nameslug}`} className='text-decoration-none text-dark col-6 col-md-3 inline-block text-start cursor-pointer hover:scale-105 ease-in-out duration-300 p-2'>
                                        <div style={{'position':'relative', 'top':0, 'bottom':0, 'left':0, 'right':0,}}>
                                            <img style={{'height':280, 'width':250, 'objectFit':'contain'}} key={item.name} src={`${apiUrl}/${item.picture}`} alt={item.name} />
                                        </div>
                                        <div className='text-center py-1'>
                                            <p style={{'marginBottom':10}} className='h6 text-wrap py-2'>{item.name}</p>
                                            <span style={{ 'paddingLeft':5, 'paddingRight':5}} className='text-dark'>{formatAmount(item.price)}</span>
                                        </div>
                                    </Link>
                                )
                            })}
                        
        
        </div>
    )
}

export default RandomProducts;