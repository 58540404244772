import React, { useState, useEffect, useContext } from 'react';
import {Header, Footer, ProjectGallery} from './';
import { Link } from 'react-router-dom';
import { SiteContext } from '../context/index';
import Accordion from 'react-bootstrap/Accordion'

const HsePolicy = () => {
    const { userData, apiUrl, isLoggedOn, categories, isMobile  } = useContext(SiteContext);
      

    return (
        <div>
            <Header />

            <div style={{'backgroundColor':'white'}} className='px-3 py-5 my-3'>
            <div className='container py-3'>
                <div className='row'>
                    <div className='col-md-6'>
                        <img className='rounded d-none d-md-block' src='../450B1445-C28E-474C-94B7-4879741491BF_4_5005_c.jpeg' width='100%' height={350} alt='hse policy' />
                    </div>
                    <div className='col-md-6 p-4'>
                        <h2>HSE Policy</h2>

                        <p>
                        The HSE Plan covers the following: Occupational Health, (excluding Clinical medicine), Safety, Health and Environment of the entire project. The scope covers core construction activities and construction support activities such as logistics, land and marine transport, marine haulage, etc.
                        </p>
                        <p>
                        The HSE plan outlines the requirements and guidelines to achieve the set HSE objectives. The plan as a tool will be used to follow-up major issues and other remedial actions from all our construction activities and project HSE Studies such as HSE-MS, Human Factor Engineering, (ergonomics) etc.
                        </p>
                        
                    </div>
                </div>
            </div>
            </div>

    <div className='container my-3 py-3'>
        <div className='row'>
            <div className='col-md-4'>
            <Accordion flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>LEADERSHIP AND COMMITMENT</Accordion.Header>
                    <Accordion.Body>
                    SAFETY 2000 Limited Management is fully committed to HSE, management system to enhance the credibility of HSE policy and objectives and to communicate HSE issues. In practice, management is committed to providing enough resources to perform all HSE –critical activities to the right quality and within the agreed schedule. The management is also committed to develop, nurture and sustain policies with the view of achieving strategic objectives
<h5 className='my-2 py-2'>HSE CULTURE</h5>
The Company’s HSE culture is based on:
A positive culture of being proactive.
Setting clear objectives as targets for HSE and reviewing performance at regular intervals.
Belief in the company’s ability to continually improve HSE performance and culture.
Unconditional acceptance by all staff of compliance with HSE policy and its implantation principles as pre-requisite for employment. 
<h5 className='my-2 py-2'>DEMONSTRATION OF VISIBLE LEADERSHIP AND COMMITMENT</h5>
The demonstration of SAFETY 2000 Limited project management commitment to HSE include amongst others:
<ul>

<li>Playing a direct role in implementing the HSE Plan.</li>
<li>Putting HSE matters high on the agenda of business meetings, from management level downwards.</li>
<li>Setting a personal example in daily work.</li>
<li>Communicating the importance of HSE considerations in business decisions.</li>
<li>Recognizing good performance when objectives are achieved.</li>
<li>Encouraging employees’ suggestions for measures to improve HSE performance.</li>
<li>Including HSE planning and review in the company’s business planning cycle and meeting structure.</li>
<li>Promoting HSE topics in company meetings.</li>
<li>Attendance and chairing of HSE meetings.</li>
<li>Acting on HSE reports.</li>
<li>Maintaining an open approach to external liaison with authorities and the general public.</li>
<li>Conducting HSE audits or inspections personally.</li>
<li>Participating in the execution of HSE plans.</li>
<li>Spending time in the field for ad-hoc operational visits as well as for structured programs of Community visits and Management Facilities’ Inspections.</li>
<li>Participating in incident investigation.</li>
<li>Communicating with contractors on HSE matters.</li>
<li>Attending HSE meetings/conference outside the company. Setting specific HSE.</li>
<li>Task and targets for individuals and departments.</li>
</ul>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                    <Accordion.Header>SAFETY POLICY</Accordion.Header>
                    <Accordion.Body>
                    SAFETY 2000 Limited aims to ensure that risk to personnel and equipment involved in the execution of all Projects are identified, assessed, and controlled and that recovery measures are in place. A system of incidents and accident reporting will be implemented.
                    </Accordion.Body>
                </Accordion.Item>

            </Accordion>

            </div>
            <div className='col-md-4'>
            <Accordion flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>COMMITMENT</Accordion.Header>
                    <Accordion.Body>
                    <span className='h5'>COMMITMENT:</span> The site management shall demonstrate positive proactive culture in the management of this project. The management of SAFETY 2000 Limited shall be a commitment to the following:
<ul>
<li>Setting a personal example in daily work.</li>
<li>Putting HSE matters high on the agenda of business meetings, from management level downwards.</li>
<li>Communicating the importance of HSE considerations in business decisions.</li>
<li>Recognizing good performance when objectives are achieved.</li>
<li>Encouraging employees’ suggestions for measures to improve HSE performance.</li>
<li>Promoting HSE topics in company meetings.</li>
<li>Including HSE planning and review in the company’s business planning cycle and meeting structure.</li>
<li>Attendance and chairing of HSE meetings.</li>
<li>Acting on HSE reports.</li>
<li>Maintaining an open approach to external liaison with authorities and the general public.</li>
<li>Conducting HSE audits or inspections personally.</li>
<li>Participating in the execution of HSE plans.</li>
<li>Participating in incident investigation.</li>
<li>Communicating with contractors on HSE matters.</li>
<li>Setting specific HSE tasks and targets for individuals and departments.</li>
</ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>HEALTH POLICY</Accordion.Header>
                    <Accordion.Body>
                    SAFETY 2000 Policy is to operate a healthy workplace with no health incidents and to improve the health awareness of SAFETY 2000 Limited personnel and its subcontractors.
The strategy will be
Conceptual/Design Phase
Ergonomic factors relating to facilities design will be incorporated with the aim of providing an operator-friendly environment. Before mobilization, all personnel and sub-contractors shall be medically tested, as HIV enlightenment will be imitated. We shall have one qualified Nurse at the site throughout the project duration.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            </div>
            <div className='col-md-4'>
            <Accordion flush>                
                <Accordion.Item eventKey="0">
                    <Accordion.Header>HSE POLICY STATEMENT</Accordion.Header>
                    <Accordion.Body>
                    SAFETY 2000 Limited understands that good HSE performance is an integral part of efficient and profitable business management. We will, therefore, be guided by the following principles.
<ul>
<li>To preserve the health and safety of personnel and others who may be affected.</li>
<li>To protect the environment.</li>
<li>To safeguard human and material resources.</li>
<li>To develop and maintain cordial relationships with the client, host communities, and others.</li>
<li>HSE is of equal importance to other business activities.</li>
<li>HSE management is the line responsibility.</li>
<li>Everybody is responsible for HSE</li>
<li>No activity shall be carried out unless considered safe.</li>
<li>Safeguard the health and safety of all employees, contractors, and third parties.</li>
<li>Strive to minimize the impact of activities on the environment.</li>
<li>Maintain good relationships with host communities.</li>
<li>Strive to enhance the security of life and equipment.</li>
<li>We believe that every job could be done safely.</li>
</ul>

                    </Accordion.Body>
                </Accordion.Item>

            </Accordion>

            </div>
        </div>
    </div>


            <Footer />
        </div>
    )
}

export default HsePolicy;