import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavigationBar } from '.';
import { SiteContext } from '../context';

const HomeCategories = () => {

    const { apiUrl, handleCurrentPage, isMobile, formatAmount } = useContext(SiteContext);

    let navigate = useNavigate();
    const [homecategories, setHomeCategories] = useState([]);
    const [msg, setMsg] = useState('');

    useEffect(() => {
        getProductsCat();
      },[])

      const getProductsCat = () => {
        fetch(`${apiUrl}/api/productcategory.php?action=fetch`, {
          method:'get'
          }).then((res) => {
              return res.json()
          }).then((res) => {
              if(res.status === 200) {
                setHomeCategories(res.categories.reverse());      
              } else if (res.status === 400) {
                  setMsg(res.msg)
                  setTimeout(() => {
                    setMsg('')
                  },3000)
              }
          }).catch((err) => {
              console.log(err)
          })
      }

      const getParts = (num) => {
        let newString = num.split(' ', 4).join(' ');
        return newString;
     }

    return (
        <div className='my-3'>
        <div className='d-flex items-center border'>
                   <div id='slider' className='w-full h-[320px] overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide'>
                          {homecategories.map((item) => {
                               return (
                                   <button onClick={() => {navigate(`/products/category/${item.pcatslug}`); handleCurrentPage('products');} } className={isMobile ? 'w-[250px] inline-block text-start cursor-pointer hover:scale-105 ease-in-out duration-300 p-2' : 'w-[350px] inline-block text-start cursor-pointer hover:scale-105 ease-in-out duration-300 p-2'}>
                                       <div style={{'position':'relative', 'top':0, 'bottom':0, 'left':0, 'right':0,}}>
                                        <img className='rounded' style={{'height':300, 'width': isMobile ? 250 : 350, 'objectFit':'cover'}} key={item.pcatname} src={`${apiUrl}/${item.pcatpicture}`} alt={item.name} />
                                        <div style={{'position':'absolute', 'padding':'10px', 'backgroundColor':'black', 'bottom':0, 'left':0, 'right':0, 'opacity':0.7}} className='text-center'>
                                            <p className='h3 text-wrap text-white py-2'>{item.pcatname}</p>
                                        </div>
                                        </div>
                                   </button>
                               )
                           })}
                       </div>
                       </div>
                       </div>
    )
}

export default HomeCategories;