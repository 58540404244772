import React, { useState, useEffect, useContext, useRef, useMemo, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { Header, Footer } from '../screens';
import { NavigationBar, Cart, Comments, Item, RandomProducts } from '.';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { SiteContext } from '../context';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    paymethod: Yup.string()
      .required('Choose a payment method please')
  });

const ProductDetail = () => {

  const {slug} = useParams();
  let navigate = useNavigate();

    const { userData, apiUrl, siteMaxWidth, formatAmount, cart, products, getOrderTotal, getOrderItemsTotal, manageCart, singleProduct } = useContext(SiteContext);

    const [item, setItem] = useState({});
    const [gotItem, setGotItem] = useState(false);
    const [someItem, setSomeItem] = useState({});
    const [cartContents, setCartContents] = useState([]);
    const [orderTotal, setOrderTotal] = useState('');
    const [msg, setMsg] = useState('');
    const [canOrder, setCanOrder] = useState(false);
    const [key, setKey] = useState('Description');

  useEffect(() => {
    getProduct();
    window.scrollTo(0, 0);
  }, [slug])
  
  const getProduct = () => {
    let theitem = products.filter((item) => item.nameslug === slug);

    setItem(theitem[0]);
    updateViews();
}

    const updateViews = () => {

       fetch(`${apiUrl}/api/products.php?action=updateview&slug=${slug}`, {
          method:'get'
        }).then((res) => res.json()
        ).then((res) => {
            
        }).catch((err) => {
            console.log(err)
        })
  
    }
  

    const handleSubmit = (values, {setSubmitting, resetForm}) => {

      let formData = new FormData();

      formData.append('productid', singleProduct ? item.id : '');
      formData.append('productname', singleProduct ? item.name : '');
      formData.append('orderdetails', singleProduct ? [] : JSON.stringify(cart));
      formData.append('amount', singleProduct ? item.price : orderTotal);
      formData.append('customerid', userData.id);
      formData.append('customername', userData.name);
      formData.append('customerphone', userData.phone);
      formData.append('customeraddress', `${userData.address} ${userData.residencestate}`);
      formData.append('customerlatitude', userData.customerlatitude);
      formData.append('customerlongitude', userData.customerlongitude);
      formData.append('paymode', values.paymethod);
      formData.append('action', 'create');

      fetch(`${apiUrl}/api/orders.php`, {
          method: 'post',
          body: formData
      })
      .then((res) => res.json())
      .then((response) => {
          if(response.status === 200) {
              setMsg(response.msg);
              setTimeout(() => {
                setMsg('');
                },5000);
                setCanOrder(false);
                !singleProduct && manageCart('clear');
                navigate(`/ordercomplete/${response.orderref}`, {state: {'pay': values.paymethod}});
              } else if(response.status === 400){
              setMsg(response.msg);
              setTimeout(() => {
                setMsg('');
                },5000);  
            }
      })
      .catch((err) => {
          alert(err)
      })
  
  }


    
    return (
        <div>
          <Header />

        <div style={{'maxWidth': siteMaxWidth}} className='container'>

          <div className='row'>
            <div className='col-lg-12'>
              <Item item={item} handleSubmit={handleSubmit} apiUrl={apiUrl} formatAmount={formatAmount} />

              <RandomProducts />
            </div>          
          </div>

            {!item && <div>
              <div className='my-1 py-1'> {msg}</div>

              <p>Product not found</p>  
            </div>}
            </div>

            <Footer />
        </div>
    )
}

export default ProductDetail;