import React, { useState, useEffect, useContext } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

const PaypalCheckoutButton = ({ product, doCustomerPayment, orderref }) => {
    const [paidFor, setPaidFor] = useState(false);
    const [error, setError] = useState(null);

    const handleApprove = (orderId) => {
        doCustomerPayment(orderref);
        setPaidFor(true);
    }

    if (paidFor) {
        alert("Thank you for your order. You have successfully paid for your order");
    }

    if (error) {
        alert(error)
    }

    return <PayPalScriptProvider
        options={{
            "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
            currency: "GBP"
            }}
        >
        <PayPalButtons
            onClick={(data, actions) => {
                const hasAlreadyBought = false;
                if (hasAlreadyBought) {
                    setError("You have already purchased this item");
                    return actions.reject();
                } else {
                    return actions.resolve();
                }

            }}
            createOrder={(data, actions) => {
                return actions.order.create({
                    purchase_units: [
                        {
                            description: product.description,
                            amount: {
                                "currency_code": "GBP",
                                "value": product.price,
                                // "breakdown": {
                                //     "item_total": {  /* Required when including the items array */
                                //     "currency_code": "GBP",
                                //     "value": product.price
                                //     }
                                // }
                            },
                            // "items": 
                            //     cart.map((it, i) => {
                            //         return {
                            //             "name": it.item, /* Shows within upper-right dropdown during payment approval */
                            //             "description": it.item, /* Item details will also be in the completed paypal.com transaction view */
                            //             "unit_amount": {
                            //                 "currency_code": "GBP",
                            //                 "value": it.price
                            //             },
                            //             "quantity": it.qty
                            //         }
                            //     }),
                            
                        },
                    ],
                });
            }}
            onApprove={async (data, actions) => {
                const order = await actions.order.capture();
                console.log('order', order);

                handleApprove(order.orderId)

            }}
            onCancel={() => { }}
            onError={(err) => {
                setError(err);
                console.log("error", err)
            }}
        />
    </PayPalScriptProvider>

}

export default PaypalCheckoutButton;