import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { NavigationBar, DoQuantity } from '.';
import { SiteContext } from '../context';

const AllProducts = () => {

    const { apiUrl, formatAmount, slugify, categories, products } = useContext(SiteContext);

    const [msg, setMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    let navigate = useNavigate();

    return (
        <div>
         {msg !== '' ? <div className='my-2'><span className='p-2 my-2 border border-info text-dark'>{msg}</span></div> : null}


         {categories.map((item) => {
                const cat = item;
                return (
                    <div className='my-3 py-2'>
                    <div style={{'backgroundColor':'#eeeeee'}} className='m-0 px-2 py-2 h4 d-flex justify-content-between align-items-center rounded'>
                       <div className='text-dark'>{cat}</div>
                       <div className='h6'><Link className='text-decoration-none text-dark' to={`/products/category/${slugify(item)}`}>{`SEE ALL > `}</Link></div>
                    </div>
                    <div className='relative flex items-center'>
                    <div id='slider' className='w-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide'>
                           {products.filter((item) => item.category === cat).map((item) => {
                                return (
                                    <div className='w-[350px] inline-block text-start cursor-pointer p-2'>
                                        <div style={{'position':'relative', 'top':0, 'bottom':0, 'left':0, 'right':0, 'paddingLeft':10, 'paddingRight':10}} className='d-flex justify-content-center align-content-center'>
                                        <button onClick={() => navigate(`/products/detail/${item.nameslug}`)}>
                                            <img style={{ 'height': 300, 'width': 340, 'objectFit': 'contain' }} key={item.name} src={`${apiUrl}/${item.picture}`} alt={item.name} />
                                                {item.qty <= 0 &&
                                            <div style={{ 'position': 'absolute', 'left': 10, 'bottom': 50, 'padding': 10 }}>
                                                <span className='p-1 rounded bg-dark text-white'>Sold out</span>
                                            </div>}
                                        </button>
                                            
                                        </div>
                                        <div style={{ 'padding': '10px', }} className='text-center'>
                                            <p style={{'marginBottom':5}} className='h6 text-wrap py-2 text-dark'>{item.name}</p>
                                            <p className='text-dark'>{formatAmount(item.price)}</p>
                                        </div>
                                        <div className='text-center'>
                                            <button className='rounded-pill bg-dark text-white py-2 w-50' onClick={() => navigate(`/products/detail/${item.nameslug}`)}>Details</button>
                                        </div>
                                    </div>

                                )
                            })}
                        </div>
                        </div>
                    </div>
        
                )
            })
        }

        
        </div>
    )
}

export default AllProducts;