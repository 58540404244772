import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function Seo({ title, description, url, img }) {
    return (
        <Helmet>
            { /* Standard metadata tags */}
            <title>{title}</title>
            <meta name='description' content={description} />
            { /* End standard metadata tags */}
            { /* Facebook tags */}
            <meta property="og:type" content="Website" />
            <meta property="og:title" content={title} />
            <meta property="og:url" content={url} />
            <meta property="og:image" content={img} />
            <meta property="og:description" content={description} />
            <meta property='og:site_name' content="Just Web Services" />

            { /* End Facebook tags */}
            { /* Twitter tags */}
            <meta name="twitter:creator" content="AfamBC" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            { /* End Twitter tags */}

            <meta name="keywords" content="Websites, mobile app, android, iOS, software, hosting, domain name" />
            <meta name="description" content={description} />

        </Helmet>
    )
}