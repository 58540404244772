import React, { useContext, useState } from 'react';
import { Header, Footer } from './';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { NavigationBar } from '../components';
import { useNavigate } from 'react-router-dom';
import { SiteContext } from '../context';
import Seo from './Seo';

const validationSchema = Yup.object().shape({
    yourname: Yup.string()
        .label('Your name')
        .required()
        .min(3, 'Must have at least 3 characters'),
    message: Yup.string()
        .label('Message')
        .required()
});


const Testimonial = () => {

    const { siteconfig, apiUrl } = useContext(SiteContext);

    const navigate = useNavigate();

    const [uploadedPic, setUploadedPic] = useState(null);
    const [msg, setMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [showForm, setShowForm] = useState(true);

    const handleSubmit = (values, { setSubmitting, resetForm, setShowModal, selectedPackage, selectedUser }) => {

        setLoading(true);

        let formData = new FormData();

        formData.append('name', values.yourname);
        formData.append('position', values.position);
        formData.append('photo', values.photo);
        formData.append('message', values.message);
        formData.append('yourchoice', values.yourchoice);
        formData.append('action', 'send');

        return fetch(`${apiUrl}/api/testimonials.php`, {
            method: 'post',
            body: formData
        })
            .then((res) => res.json())
            .then(async (res) => {

                console.log(res);

                if (res.status === 200) {
                    resetForm({});
                    setSubmitting(false);
                    setMsg(res.msg);
                    setShowForm(false);
                    setLoading(false);
                } else if (res.status === 400) {
                    setErrorMsg(res.msg);
                    setTimeout(() => {
                        setErrorMsg('');
                    }, 5000);
                    setSubmitting(false);
                    setLoading(false);
                } else {
                    alert(
                        'Testimonial could not be sent',
                        'Error connection to the server please check your details and try again',
                        [{ text: 'Okay' }]
                    );
                }
            })
            .catch((error) => {
                console.log('Api call error', error.message);
            });
    };


    return (
        <div>
            <Seo
                title={`Testimonials | Just Web Services`}
                description="Provide feedback on services we render to you"
                url={`${apiUrl}/post-testimonial`}
                img={`${apiUrl}/${siteconfig.shoplogo}`}
            />
            <Header />

            <div className='container py-3'>
                <NavigationBar section={'Post testimonial'} goback={false} />
                <div className='row p-3'>
                    <div className='col-md-8 mt-2'>
                        <h4>Post Testimonial</h4>
                        <p className='text-muted fst-italic'>Post a testimonial about services rendered by Just Web Services</p>
                        <div className='my-2 d-flex justify-content-between'>
                            <div className='align-items-center'>
                                {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                            </div>
                        </div>

                        {loading && <div className='d-flex justify-content-center align-content-center my-5'>
                            <div class="spinner-border text-secondary" role="status" />
                        </div>}

                        {showForm ? <Formik
                            initialValues={{
                                yourname: '',
                                position: '',
                                yourchoice: '',
                                message: '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                handleSubmit(values, { setSubmitting, resetForm });
                            }}
                        >
                            {({
                                handleChange,
                                values,
                                handleSubmit,
                                errors,
                                isValid,
                                isSubmitting,
                                handleBlur,
                                touched,
                                setFieldValue
                            }) => (
                                <div>
                                    <form onSubmit={handleSubmit} encype="multipart/form-data">
                                        <div className='my-2'>

                                            <div className='form-group my-3'>
                                                <div className='h6'>
                                                    <label htmlFor="yourname">Name <span style={{ 'position': 'relative', 'top': -1 }} className='h5'>*</span></label>
                                                </div>
                                                <Field
                                                    id="yourname"
                                                    name="yourname"
                                                    value={values.yourname}
                                                    placeholder="Your name"
                                                    onBlur={handleBlur('yourname')}
                                                    autoCapitalize="none"
                                                    style={{
                                                        padding: 10,
                                                    }}
                                                    className="form-control"
                                                />
                                                {touched.yourname && errors.yourname && <div className='py-1'>
                                                    <span className='text-danger'>{touched.yourname && errors.yourname}</span>
                                                </div>}
                                            </div>

                                            <div className='form-group my-3'>
                                                <div className='h6'>
                                                    <label htmlFor="position">Position <span style={{ 'position': 'relative', 'top': -1 }} className='h5'>*</span></label>
                                                </div>
                                                <Field
                                                    id="position"
                                                    name="position"
                                                    value={values.position}
                                                    placeholder="Your position eg manager"
                                                    onBlur={handleBlur('position')}
                                                    autoCapitalize="none"
                                                    style={{
                                                        padding: 10,
                                                    }}
                                                    className="form-control"
                                                />
                                                {touched.position && errors.position && <div className='py-1'>
                                                    <span className='text-danger'>{touched.position && errors.position}</span>
                                                </div>}
                                            </div>

                                            <div className='form-group my-3'>
                                                <div>
                                                    <label htmlFor="photo"> Upload picture (<span className='text-muted small fst-italic'>Optional</span>)</label>
                                                </div>
                                                <input id="photo" name="photo" type="file" onChange={(event) => {
                                                    setFieldValue("photo", event.currentTarget.files[0]);
                                                    setUploadedPic(event.currentTarget.files[0]);
                                                }} />
                                                {uploadedPic && <img className='my-2' src={URL.createObjectURL(uploadedPic)} class="img-fluid mt-2" width={200} height={100} id="output_image" />}
                                            </div>

                                            <div className='form-group my-3'>
                                                <div className='h6'>
                                                    <label htmlFor="message">Message <span style={{ 'position': 'relative', 'top': -1 }} className='h5'>*</span></label>
                                                </div>
                                                <Field
                                                    as='textarea'
                                                    id="message"
                                                    name="message"
                                                    value={values.message}
                                                    placeholder="Message"
                                                    onBlur={handleBlur('message')}
                                                    autoCapitalize="none"
                                                    style={{
                                                        padding: 10
                                                    }}
                                                    rows='4'
                                                    className="form-control"
                                                />
                                                {touched.message && errors.message && <div className='py-1'>
                                                    <span className='text-danger'>{touched.message && errors.message}</span>
                                                </div>}
                                            </div>
                                        </div>

                                        <button
                                            type='submit'
                                            buttonType="outline"
                                            onClick={handleSubmit}
                                            title="Submit"
                                            disabled={!isValid || isSubmitting}
                                            loading={isSubmitting}
                                            className='btn btn-primary'
                                        >
                                            Submit
                                        </button>

                                    </form>
                                </div>
                            )}
                        </Formik> :
                            <div className='my-1 py-1'>
                                {msg !== '' ? <div className='p-2'><span className='p-2 text-dark'>{msg}</span></div> : null}
                            </div>}

                    </div>

                    <div className='col-md-4'>
                        <h5>Working Time</h5>
                        <p className='p-1'><i className='bi bi-clock'></i>: MON-SAT 06:00-18:00</p>
                        <p className='p-1'><i className='bi bi-phone'></i>: {siteconfig.shopphone}</p>
                        <p className='p-1'><i className='bi bi-envelope'></i>: {siteconfig.shopemailaddress}</p>

                        <h5>Got Questions?</h5>
                        <div className='bg-danger text-white p-3 rounded-1'>
                            <p className='p-1'>Submit your question or inquiry online and we would get back to you shortly</p>
                            <button className='p-2 border-0 rounded-3' onClick={() => navigate('/contact')}>Contact us</button>
                        </div>

                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default Testimonial;