import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Header, Footer } from '.';
import { getHeading } from '../utils';
import { SiteContext } from '../context';

const ProductsCategory = () => {

    const { apiUrl, siteMaxWidth, formatAmount, slugify, products } = useContext(SiteContext);

    const [items, setItems] = useState([]);
    const [msg, setMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    let navigate = useNavigate();
    let {slug} = useParams();

    useEffect(() => {
        getProducts();
        window.scrollTo(0,0)
    },[slug])

    const getProducts = () => {

        let filteredProducts = products.filter((item) => slugify(item.category) === slug);
        setItems(filteredProducts);
    }


    return (
        <div>
            <Header />

        <div style={{'maxWidth': siteMaxWidth}}  className='container'>
    
        <div className='container mt-3 px-4 py-2 bg-light'>
            <Link className='text-decoration-none' to='/'>Home</Link> / <Link className='text-decoration-none' to='/products'>Products</Link> / <span>{slug.charAt(0).toUpperCase()+''+slug.slice(1).split('-').join(' ')}</span>  
        </div>

            {msg !== '' ? <div className='my-2'><span className='p-2 my-2 border border-info text-dark'>{msg}</span></div> : null}

                <div className='my-1'>
                    <div className='row my-3 py-3'>
                {items.map((item,i) => {
                    
                    return (
                    <div key={i} className='col-lg-3 col-md-4 col-sm-6 text-center'>
                        <button className='text-decoration-none text-dark border border-light rounded py-2 m-2' onClick={() => navigate(`/products/detail/${item.nameslug}`)}>
                            <div style={{'position':'relative', 'top':0, 'bottom':0, 'left':0, 'right':0, 'paddingLeft':10, 'paddingRight':10}} className='d-flex justify-content-center align-content-center'>
                                <img style={{ 'height': 250, 'width': 340, 'objectFit': 'contain' }} key={item.name} src={`${apiUrl}/${item.picture}`} alt={item.name} />
                                {item.qty <= 0 && <div style={{ 'position': 'absolute', 'left': 10, 'bottom': 10, 'padding':10 }}>
                                    <span className='p-1 rounded bg-dark text-white'>Sold out</span>
                                </div>}
                                
                            </div>
                            <div style={{ 'padding': '10px', }} className='text-center'>
                                    <p style={{'marginBottom':10}} className='h6 text-wrap py-2 text-dark'>{item.name}</p>
                                    <span className='text-dark'>{formatAmount(item.price)}</span>
                                </div>
                        </button>    
                    </div>
                    )})
                }
                </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ProductsCategory;